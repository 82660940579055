

  .coolBeans {
    width: 70%;
    border: 2px solid currentColor;
    border-radius: 3rem;
    /* color:#86af49; */
    font-family: roboto;
    font-size: 2rem;
    font-weight: 100;
    overflow: hidden;
    padding: 1rem 2rem;
    position: relative;
    text-decoration: none;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    background:transparent;
  }
  .coolBeans::after {
    background-color:black  ;
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .coolBeans:hover::after {
    transform: translate(0, 0);
  }
  .coolBeans:hover {
    border: 2px solid transparent;
    color: white;
    transform: scale(1.05);
    will-change: transform;
  }
  
