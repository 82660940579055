

.center-box {
  /* max-width: 600px; */
  width: 50%;
  height: 900vh;
  margin-top: 18rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px;
  padding: 2rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: center; */
  /* align-content: center; */
}

.center-box span{

  padding-left: 1rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid grey;
}

.img-container{
  width: 100%;
   height:18.75rem;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px;

}

.img-container img{
  height: 100%;
  object-fit: contain;
  border: 1px solid black;

}

.container{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #F5F5F5;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  overflow: auto;

}


.navbar{
  height: 4rem;
  background-color: #034f84;
  width: 100%;
  position: absolute;
  top: 0px; 
  opacity: 0.8;
  font-family: 'Roboto Condensed', sans-serif;
}

.navbar > h2{
 color: #deeaee;
 margin-left: 2rem;
 
}


.valid-box{
  padding: 1rem;
  background-color: lightgreen;
  min-height: 4rem;
  border: 1px;
  border-color: gray;
  text-align: center;
}

.invalid-box{
  padding: 1rem;
  background-color: lightcoral;
  height: 4rem;
  border: 1px;
  border-color: gray;
  text-align: center;
}

.circular-box{
  align-self: center;
}


.error-logo{
  width: 50%;
  height: 50%;

}
.comprovante-button{
padding-top: 2.5rem;
}