.App {
  text-align: center;
}

html {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-height: 800px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-height: 900px) {
  html {
    font-size: 13.5px;
  }
}

@media only screen and (max-height: 600px) {
  html {
    font-size: 12px;
  }
}




body {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(rgb(221 246 255), rgb(186 251 119 / 21%)); */
}

