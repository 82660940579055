* {
  margin: 0;
  padding: 0;
}

.background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.container-p {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box {
  height: 88vh;
  width: 60vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 120px auto 100px;
  gap: 0px 0px;
  grid-template-areas:
    "header"
    "main"
    "footer";
  overflow: hidden;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: 1px;
}

@media (max-width: 801px) {
  .box {
    width: 100vw;
  }
}
header {
  grid-area: header;
  display: flex;
  align-items: center;
}
main {
  grid-area: main;
  overflow: auto;
  padding-right: 10px;
}

footer {
  grid-area: footer;
}

footer > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.lista {
  padding-top: 10px;
  list-style-type: disc;
  list-style-position: inside;
}

.lista li {
  padding-left: 10px;
}
.crop-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.crop-container img {
  max-height: calc(80vh - 200px);
}

.container-img-cropped {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-img-cropped img {
  height: 100%;
}

.centering {
  width: 100%;
  display: flex;
  justify-content: center;
}

.v-centering {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20%;
}

.pages {
  display: flex;
  flex-direction: column;
  height: 100%;
}

