
.home{
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color:  #50394c;
}

.home > div > img{
    display: block;
    height: 40vh;
    width: auto;
}